import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import PageSection from "../PageSection/"

import "./index.scss";

class ContactSection extends React.Component {
    render(){
        return (
            <PageSection id="contact" heading="Contact">
                <div className="part">
                    <span>109, 110, Nidhi Industrial Estate, Shiv Shankar Industrial Complex No. 2, NH No. 8, Village Waliv, Vasai (East) 401208, Dist. Palghar, Maharashtra, India. </span>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.google.com/maps/place/Bird+Meditech/@19.4184853,72.873936,15z/data=!4m5!3m4!1s0x0:0xa62baae90cb29093!8m2!3d19.4184853!4d72.873936">
                            Open map
                    </a>
                </div>
                <div className="part">
                    <a href="tel:+91 9325752548">+91 9325752548</a>
                </div>
                <div className="part">
                    <a href="mailto:birdmeditech@gmail.com">birdmeditech@gmail.com</a>
                </div>
                {/* Add social media icons */}
                <div className="social-icons">
                    <a href="https://www.linkedin.com/company/bird-meditech" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin"></i>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100067570025212" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a href="https://www.youtube.com/@BirdMeditech" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-youtube"></i>
                    </a>
                </div>
            </PageSection>
        )
    }
}
export default ContactSection;